html,
body {
  height: 100%;
}

body {
  display: flex;
  justify-content: center;
  padding-top: 70px;
  padding-bottom: 10px;
}

.form-signin {
  width: 100%;
  max-width: 400px;
  padding: 15px;
  margin: auto;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.dot {
  height: 5px;
  width: 5px;
  background-color: black;
  border-radius: 50%;
  display: inline-block;
}

.yellowdot {
  height: 15px;
  width: 15px;
  background-color: yellow;
  border-radius: 50%;
  display: inline-block;
}

.whitedot {
  height: 15px;
  width: 15px;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
}

.reddot {
  height: 5px;
  width: 5px;
  background-color: red;
  border-radius: 50%;
  padding: 5px;
  display: inline-block;
}

.scoreinput {
  border: none;
  outline: none;
  width: 28px;
  text-align: center;
}

.thick {
  font-weight: bold;
}

th, td {
  border: thin solid lightgrey;
  border-collapse: collapse;
  padding: 2px;
  text-align: center;
}

.parrow {
  border: thin solid lightgrey;
  border-collapse: collapse;
  vertical-align: text-top;
}

.vertcenter {
  vertical-align: middle;
}

.leftrow {
  border: thin solid lightgrey;
  border-collapse: collapse;
  text-align: left;
}

.centerrow {
  border: thin solid lightgrey;
  border-collapse: collapse;
  text-align: center;
}

.scorecell {
  background-color: lightblue;
}

.scoretable {
  border: thin solid lightgrey;
  border-collapse: collapse;
  table-layout: fixed;
}

.pad-left-right {
  padding-left: 10px;
  padding-right: 10px;
}

.scoretablefirstcol {
  width: 45px;
}

.scoretablecol {
  width: 31px;
}

.acctable {
  table-layout: fixed;
}

.acctablecolname {
  width: 250px;
  text-align: left;
}

.acctablecolscore {
  width: 100px;
  text-align: right;
}

.flighttable {
  table-layout: fixed;
  padding: 10px;
  border: thin solid lightgrey;
  border-collapse: collapse;
}

.flighttablecol {
  width: 100px;
}

.flightscoreinput {
  border: thin solid lightgrey;
  outline: none;
  width: 60px;
  text-align: center;
  font-size: 1.1em;
}

.scoremodal {
  position: relative;
  display: table; /* This is important */ 
  overflow-y: auto;    
  overflow-x: auto;
  width: auto;
  min-width: 400px; 
}

.centeralign {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

.drctable {
  border: none;
  border-collapse: collapse;
  table-layout: fixed;
}

.drcimage {
  border: none;
  width: 100px;
  height: 100px;
}

.ryder-score-table {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ryder-score-total {
  width: 70px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: larger;
  font-weight: bold;
  border-radius: 10px;
}

.ryder-score-total-small {
  width: 50px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  border-radius: 12px;
}

.ryder-score-match {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}

.stt-final {
  background-color: blue;
  color: white;
}

.lat-final {
  background-color: red;
  color: white;
}

.square-final {
  background-color: darkgray;
  color: white;
}

.stt-prelim {
  background-color: cornflowerblue;
  color: white;
}

.lat-prelim {
  background-color: lightcoral;
  color: white;
}

.square-prelim {
  background-color: silver;
  color: white;
}

.home-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  padding: 10px;
}

.flex-container {
  display: flex;
  gap: 10px;
}
